import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2424468505/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A guide for card enrollment with the React Native SDK `}<a style={{
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/react-native/guide-v1">{`v1`}</a>{` `}<a style={{
        "marginRight": "auto",
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/react-native/guide-v2">{`v2`}</a></h1>
    <p>{`Please take the following steps to integrate and configure the SDK for your Loyalty use case application.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: If an example project helps with your SDK integration & configuration, please check our `}<a parentName="p" {...{
          "href": "https://github.com/Enigmatic-Smile/rn-sdk"
        }}>{`GitHub repository`}</a>{`.`}</p>
    </blockquote>
    <h3>{`1. Set up your Fidel API account & your Transaction Select program`}</h3>
    <p>{`To get started, you'll need a Fidel API account. `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/sign-up"
      }}>{`Sign up`}</a>{`, if you didn't create an account yet.`}</p>
    <p>{`If you didn't create a program for your application yet, please create a Transaction Select program from your Fidel API dashboard (or via the API).`}</p>
    <h3>{`2. Integrate the React Native SDK into your project`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Add the Fidel API React Native library:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`using `}<inlineCode parentName="li">{`npm`}</inlineCode>{`: `}
            <pre parentName="li"><code parentName="pre" {...{}}>{`npm install fidel-react-native@1.6.4 --save
`}</code></pre>
          </li>
          <li parentName="ul">{`using `}<inlineCode parentName="li">{`yarn`}</inlineCode>{`:`}
            <pre parentName="li"><code parentName="pre" {...{}}>{`yarn add fidel-react-native@1.6.4
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In your `}<inlineCode parentName="p">{`ios`}</inlineCode>{` folder, run the following command to install the Fidel dependency:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`pod install
`}</code></pre>
    <h3>{`3. Import the SDK module`}</h3>
    <p>{`In your project files where you want to use the SDK, please import `}<inlineCode parentName="p">{`Fidel`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Fidel from 'fidel-react-native';
`}</code></pre>
    <h3>{`4. Start setting up the SDK by setting the SDK Key`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Click on your account name `}<em parentName="li">{`(on the top-left hand side of the dashboard)`}</em>{` -> then on `}<inlineCode parentName="li">{`Account Settings`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Plan`}</inlineCode>{` tab and copy your `}<inlineCode parentName="li">{`Test`}</inlineCode>{` or `}<inlineCode parentName="li">{`Live`}</inlineCode>{` SDK Key.`}</li>
      <li parentName="ul">{`Set your SDK Key in your app:`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/select/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setup({
    apiKey: yourSdkKey,
});
`}</code></pre>
    <h3>{`5. Add your Program ID`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Programs`}</inlineCode>{` section of your Fidel API dashboard.`}</li>
      <li parentName="ul">{`Click on the `}<inlineCode parentName="li">{`Program ID`}</inlineCode>{` of the program that you want to enroll cards into. The program ID will be copied to your pasteboard.`}</li>
      <li parentName="ul">{`Set your Program ID in your app:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setup ({
    apiKey: yourSdkKey,
    programId: 'Your-program-ID',
});
`}</code></pre>
    <h3>{`6. Configure the cardholder consent management feature`}</h3>
    <p>{`Asking for consent from the cardholder to enroll the card in your program is an important part of the SDK. The cardholder will need to read & agree with the conditions expressed using the consent language displayed during the card enrollment process. Making it clear for cardholders is essential.`}</p>
    <p>{`You can use the `}<inlineCode parentName="p">{`setOptions`}</inlineCode>{` function to set the following properties:`}</p>
    <h4>{`Set your company name`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setOptions ({
    companyName: 'Your Company Name',
});
`}</code></pre>
    <h4>{`To support US or Canada issued cards, please set your terms and conditions`}</h4>
    <p>{`You need to set your terms and conditions URL if you would like to:`}</p>
    <p>{`a. support all the countries that Fidel API supports`}</p>
    <p>{`b. set a specific `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` set of countries AND include US or Canada in your set of allowed countries.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setOptions ({
    companyName: 'Your Company Name',
    termsConditionsUrl: 'https://yourwebsite.com/terms',
});
`}</code></pre>
    <h4>{`Explain how the cardholder can opt out (optional, but recommended)`}</h4>
    <p>{`Please inform the cardholder about how to opt out of transaction monitoring in your program.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setOptions ({
    companyName: 'Your Company Name',
    termsConditionsUrl: 'https://yourwebsite.com/terms',
    deleteInstructions: 'how can the cardholder opt out',
});
`}</code></pre>
    <h4>{`Set your privacy policy (optional, but recommended)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setOptions ({
    companyName: 'Your Company Name',
    termsConditionsUrl: 'https://yourwebsite.com/terms',
    deleteInstructions: 'how can the cardholder opt out',
    privacyUrl: 'https://yourwebsite.com/privacy-policy',
});
`}</code></pre>
    <h3>{`7. Check your setup`}</h3>
    <p>{`You should have the SDK set up similar to what you see in the example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setup ({
    apiKey: yourSdkKey,
    programId: 'Your-program-ID',
});

Fidel.setOptions ({
    companyName: 'Your Company Name',
    termsConditionsUrl: 'https://yourwebsite.com/terms',
    deleteInstructions: 'how can the cardholder opt out',
    privacyUrl: 'https://yourwebsite.com/privacy-policy',
});
`}</code></pre>
    <h1>{`Enrollment notifications`}</h1>
    <p>{`In order to be notified about different, useful events (a card was linked, card failed to link etc.) that happen during a enrollment process, we recommend using our webhooks.`}</p>
    <p>{`If client side notifications are useful for your application, make sure to check our SDK callback reference documentation.`}</p>
    <h1>{`Enroll a card`}</h1>
    <p>{`Call the following function to open the UI and start a card enrollment process:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.openForm((error, result) => {
  if (error) {
    console.error(error);
  } else {
    console.info(result);
  }
});
`}</code></pre>
    <p>{`You can test the card enrollment flow, by setting a test SDK Key and by using the Fidel API `}<a parentName="p" {...{
        "href": "/select/cards/#test-card-numbers"
      }}>{`test card numbers`}</a>{`.`}</p>
    <p>{`If your Fidel API account is `}<inlineCode parentName="p">{`live`}</inlineCode>{` then cardholders can also enroll real, live cards. Make sure that you set a live SDK Key, in order to allow live card enrollments.`}</p>
    <h1>{`Optional: Set any of the other useful properties`}</h1>
    <p>{`Please check our SDK reference page for details about any other SDK properties that might be useful for your application.`}</p>
    <h1>{`Frequently asked questions`}</h1>
    <h3>{`How can I upgrade the React Native SDK to the latest version?`}</h3>
    <ul>
      <li parentName="ul">{`At the root of your project, run `}</li>
    </ul>
    <p><inlineCode parentName="p">{`yarn upgrade fidel-react-native`}</inlineCode>{` or `}</p>
    <p><inlineCode parentName="p">{`npm update -g fidel-react-native`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`In your `}<inlineCode parentName="li">{`ios`}</inlineCode>{` folder, run the following command to update the Fidel dependency:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`pod update Fidel
`}</code></pre>
    <h3>{`Can I customize the UI of the SDK?`}</h3>
    <p>{`The React Native SDK offers the `}<inlineCode parentName="p">{`bannerImage`}</inlineCode>{` property for you to set a custom, branded banner image that will be displayed during the card enrollment process. Please check our Reference documentation for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      